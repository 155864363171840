import { createRouter, createWebHistory } from 'vue-router';

const Main = () => import('@/views/Main.vue');
const Home = () => import('@/components/pages/Home.vue');
const Legal = () => import('@/components/pages/Legal.vue');
const ResetPassword = () => import('@/components/pages/ResetPassword.vue');
const Redirect = () => import('@/components/pages/Redirect.vue');
const Verify = () => import('@/components/pages/Verify.vue');
const Blank = () => import('@/components/pages/Blank.vue');
const NotFound = () => import('@/components/pages/NotFound.vue');

// const Admin = () => import('@/views/Admin.vue');
// const AdminHome = () => import('@/components/AdminHome.vue');
// const Users = () => import('@/components/Users.vue');
// const Products = () => import('@/components/products/Products.vue');

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
    children: [
      {
        path: '',
        name: 'home',
        component: Home
      },
      {
        name: 'legal',
        path: 'legal',
        component: Legal
      },
      {
        path: 'redirect',
        name: 'reset',
        component: Redirect
      }
    ]
  },
  {
    path: '/account',
    component: Blank,
    children: [
      {
        path: 'reset',
        name: 'reset',
        component: ResetPassword
      },
      {
        path: 'verify',
        name: 'verify',
        component: Verify
      },
    ]
  },
  // {
  //   path: '/admin',
  //   name: 'admin',
  //   component: Admin,
  //   children: [
  //     {
  //       path: 'adminHome',
  //       component: AdminHome
  //     },
  //     {
  //       path: 'users',
  //       component: Users
  //     },
  //     {
  //       path: 'products',
  //       component: Products
  //     }
  //   ]
  // },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

export default router;
