import { createApp } from 'vue';
import App from './App.vue';
import '@/assets/styles/main.css';
import router from './router';
import FontAwesomeIcon from './fonts.js';
import TextField from './components/inputs/TextField.vue';
import TextSelect from './components/inputs/TextSelect.vue';
import TextBtn from './components/buttons/TextBtn.vue';

createApp(App)
  .component('Icon', FontAwesomeIcon)
  .component('TextField', TextField)
  .component('TextSelect', TextSelect)
  .component('TextBtn', TextBtn)
  .use(router)
  .mount('#app');
