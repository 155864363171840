<script>
  export default {
    name: 'TextField',
    props: {
      text: { type: String, default: 'Click Me!!!' },
      solid: { type: Boolean, default: true },
      small: { type: Boolean, default: false },
      round: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      loading: { type: Boolean, default: false }
    }
  };
</script>

<template>
  <button
    type="button"
    role="button"
    :class="` ${
      disabled
        ? 'disabled'
        : `${solid ? 'solid' : 'alt'} ${small ? 'small' : 'regular'} ${
            round ? 'rounded' : ''
          }`
    }`"
    :disabled="disabled"
  >
    <Icon v-if="loading" :icon="['fas', 'spinner']" spin />

    <span v-else>{{ text }}</span>
  </button>
</template>

<style lang="css" scoped>
  button {
    margin: 5px 0;
    padding: 8px 25px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
  .solid {
    background-color: var(--primary);
    color: #ffffff;
  }
  .solid:hover {
    background-color: var(--primaryDim);
  }
  .alt {
    background-color: #ffffff;
    border: 1px solid var(--primary);
    color: var(--primary);
  }
  .alt:hover {
    background-color: #eeeeee;
  }
  .regular {
    font-size: 1rem;
  }
  .small {
    font-size: 0.9rem;
  }
  .rounded {
    border-radius: 500px;
  }
  .disabled {
    background-color: #ffffff;
    border: 1px solid var(--gray2);
    color: var(--gray2);
    cursor: auto;
  }
</style>
