<script>
  import Dropdown from '../menus/Dropdown.vue';

  export default {
    name: 'TextSelect',
    components: {
      Dropdown
    },
    props: {
      modelValue: { type: String, default: '' },
      label: { type: String, default: '' },
      placeHolder: { type: String, default: '' },
      required: { type: Boolean, default: false },
      name: { type: String, default: '' },
      options: { type: Array, default: () => [] },
      error: { type: String, default: '' }
    },
    methods: {
      onSelect(value) {
        this.$emit('update:modelValue', value);
        this.$emit('changed', this.name || this.label.toLowerCase());
      }
    }
  };
</script>

<template>
  <div class="field">
    <label class="field-label">
      {{ label }}
      <span v-if="!!error" class="error">{{ error }}</span>
    </label>
    <Dropdown>
      <template v-slot:selector>
        <span class="inline-block w-full h-full text-left">
          {{ modelValue }}
        </span>
      </template>
      <template v-slot:options>
        <ul>
          <li v-for="option in options" :key="option">
            <button
              type="button"
              class="w-full h-full text-left p-2 hover:bg-gray-200"
              @click="onSelect(option)"
            >
              {{ option }}
            </button>
          </li>
        </ul>
      </template>
    </Dropdown>
  </div>
</template>

<style lang="css" scoped>
  .field {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 12px 0;
  }
  .field-label {
    display: flex;
    justify-content: space-between;
    color: #444444;
  }
  .field-input {
    border-radius: 8px;
    margin: 2px 0;
    padding: 10px;
    outline: none;
    font-size: 0.97rem;
    border-color: #2c3e50aa;
  }
  .field-input:focus {
    border-color: #2c3e5099;
    box-shadow: 0 0 0 1px #44438c99;
  }
  .error {
    font-size: 0.9rem;
    color: #c42f41;
  }
</style>
