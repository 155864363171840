<script>
  export default {
    name: 'TextField',
    props: {
      modelValue: { type: [String, Number], default: '' },
      label: { type: String, default: '' },
      placeHolder: { type: String, default: '' },
      required: { type: Boolean, default: false },
      type: { type: String, default: 'text' },
      name: { type: String, default: '' },
      error: { type: String, default: '' },
      disabled: { type: Boolean, default: false }
    },
    methods: {
      onInput(event) {
        this.$emit('update:modelValue', event.target.value);
        this.$emit('changed', this.name || this.label.toLowerCase());
      }
    }
  };
</script>

<template>
  <div class="field">
    <label class="field-label">
      {{ label }}
      <span v-if="!!error" class="error">{{ error }}</span>
    </label>
    <input
      :type="type"
      :name="name"
      :value="modelValue"
      :placeholder="placeHolder"
      class="field-input"
      :class="disabled ? 'bg-gray-200 text-gray-600' : ''"
      @input="onInput"
      :disabled="disabled"
    />
  </div>
</template>

<style lang="css" scoped>
  .field {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 6px 0;
  }
  .field-label {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    color: #444444;
  }
  .field-input {
    border-radius: 8px;
    margin: 2px 0;
    padding: 8px;
    outline: none;
    font-size: 0.97rem;
    border: 1px solid #cccccc;
  }
  .field-input:focus {
    border-color: #2c3e5099;
    box-shadow: 0 0 0 1px #9cb3d6;
  }
  .error {
    font-size: 0.9rem;
    color: #c42f41;
  }
</style>
