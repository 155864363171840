<template>
  <div class="container" @focusout="handleClickaway">
    <button type="button" @click="toggleMenu" class="select">
      <slot name="selector">
        <span class="temp-select">Click Me!</span>
      </slot>
    </button>
    <Transition name="fade">
      <div v-if="isOpen" class="menu">
        <slot name="options">
          <ul>
            <li class="p-2 hover:bg-gray-300">
              <button type="button">Hello World</button>
            </li>
          </ul>
        </slot>
      </div>
    </Transition>
  </div>
</template>

<script>
  export default {
    name: 'DropDown',
    data() {
      return {
        isOpen: false
      };
    },
    methods: {
      openMenu() {
        this.isOpen = true;
      },
      closeMenu() {
        this.isOpen = false;
      },
      toggleMenu() {
        this.isOpen = !this.isOpen;
      },
      handleClickaway({ currentTarget, relatedTarget }) {
        if (!relatedTarget || !currentTarget.contains(relatedTarget)) {
          this.closeMenu();
        }
      }
    }
  };
</script>

<style scoped>
  .container {
    position: relative;
  }
  .select {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    margin: 2px 0;
    margin-bottom: 1px;
    padding: 8px;
    font-size: 0.97rem;
    outline: none;
    border: 1px solid #cccccc;
    width: 100%;
  }
  .menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    border-radius: 8px;
    padding: 4px 0;
    outline: none;
    font-size: 0.97rem;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    overflow: hidden;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
    transform: translateY(-5px);
  }
</style>
