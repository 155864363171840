import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHeart as fasHeart,
  faUserAlt as fasUser,
  faBars as fasBars,
  faSpinner as fasSpinner,
  faStar as fasStar
} from '@fortawesome/free-solid-svg-icons';
import {
  faHeart as farHeart,
  faCheckCircle as farCheckCircle,
  faTimesCircle as farTimesCircle
} from '@fortawesome/free-regular-svg-icons';

library.add(
  farHeart,
  fasHeart,
  farCheckCircle,
  fasUser,
  fasBars,
  farTimesCircle,
  fasSpinner,
  fasStar
);

export default FontAwesomeIcon;
